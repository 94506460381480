import * as React from 'react';
import { Link } from 'gatsby';

import Meta from '../components/meta';
import SecondaryPageLayout from '../components/secondary-page-layout';
import * as Text from '../localisation';
import { Url } from '../url';

import * as classes from './pricing.module.css';

function FaqItem({ question, answer }: { question: React.ReactNode, answer: React.ReactNode }) {
  return (
    <div className={classes.faqItem}>
      <h3 className={classes.faqQuestion}>
        {question}
      </h3>
      <div className={classes.faqAnswer}>
        {answer}
      </div>
    </div>
  );
}

function PricingPage() {
  return (
    <SecondaryPageLayout>
      <Meta
        title={Text.PricingPageTitle}
        url={Url.Pricing}
      />

      <div className={classes.layout}>
        <h1 className={classes.pageTitle}>
          {Text.Pricing}
        </h1>

        <div className={classes.blocks}>
          <div className={classes.pricingColumn}>
            <div className={classes.pricingColumnTitle}>{Text.PricingPageBasicTitle}</div>
            <div className={classes.pricingColumnPrice}>
              <div className={classes.pricingColumnPriceSum}>
                {Text.PricingPageBasicPrice}
              </div>
            </div>
            <div className={classes.pricingColumnDescription}>{Text.PricingPageBasicDescription}</div>

            <Link to={Url.GetStarted} className={classes.cta}>
              {Text.PricingCTA}
            </Link>
          </div>

          <div className={classes.pricingColumn}>
            <div className={classes.pricingColumnTitle}>{Text.PricingPageAnnualTitle}</div>
            <div className={classes.pricingColumnPrice}>
              <div className={classes.pricingColumnPriceSum}>
                {Text.PricingPageAnnualPrice}
              </div>
              <div className={classes.pricingColumnPricePeriod}>
                {Text.PricingPageAnnualPeriod}
              </div>
            </div>
            <div className={classes.pricingColumnSubtitle}>{Text.PricingPageAnnualSubtitle}</div>
            <div className={classes.pricingColumnDescription}>{Text.PricingPageAnnualDescription}</div>

            <Link to={Url.GetStarted} className={classes.cta}>
              {Text.PricingCTA}
            </Link>
          </div>
        </div>

        <div className={classes.faq}>
          <FaqItem question={Text.PricingFaq2Question} answer={Text.PricingFaq2Answer} />
          <FaqItem question={Text.PricingFaq3Question} answer={Text.PricingFaq3Answer} />
        </div>
      </div>

    </SecondaryPageLayout>
  );
}

export default PricingPage;
