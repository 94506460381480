// extracted by mini-css-extract-plugin
export var blocks = "pricing-module--blocks--UMzrt";
export var cta = "pricing-module--cta--kbIAm";
export var faq = "pricing-module--faq--eQQ4B";
export var faqAnswer = "pricing-module--faqAnswer--GwBlo";
export var faqItem = "pricing-module--faqItem--HVzwZ";
export var faqQuestion = "pricing-module--faqQuestion--u2W3t";
export var layout = "pricing-module--layout--OMQn3";
export var pageTitle = "pricing-module--pageTitle--D6pyq";
export var pricingColumn = "pricing-module--pricingColumn--v4inX";
export var pricingColumnDescription = "pricing-module--pricingColumnDescription--vQDmQ";
export var pricingColumnPrice = "pricing-module--pricingColumnPrice--FJvEZ";
export var pricingColumnPricePeriod = "pricing-module--pricingColumnPricePeriod--IWwNH";
export var pricingColumnPriceSum = "pricing-module--pricingColumnPriceSum--GmZyg";
export var pricingColumnSubtitle = "pricing-module--pricingColumnSubtitle--Ia1dw";
export var pricingColumnTitle = "pricing-module--pricingColumnTitle--kQ3gg";
export var text = "pricing-module--text--UKNcK";